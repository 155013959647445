<script lang="ts" setup>
	import useSizes from "~/composables/useSizes";
	import type { PrimitiveColors, Sizes } from "~/utils/enums";

	const props = withDefaults(
		defineProps<{
			size: Sizes;
			color: PrimitiveColors;
		}>(),
		{
			color: "black",
		}
	);

	const { size, color } = toRefs(props);

	const { isSizeSmall, isSizeMedium, isSizeLarge } = useSizes(size);
</script>

<template>
	<svg class="icon-vertical-arrow" v-if="isSizeLarge" width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_404_16)">
			<path
				d="M9.7071 9.54289C9.31658 9.93341 8.68341 9.93341 8.29289 9.54289L0.636033 1.88603C0.245509 1.49551 0.245508 0.862342 0.636033 0.471818C1.02656 0.0812935 1.65972 0.0812935 2.05025 0.471818L9.7071 8.12867C10.0976 8.5192 10.0976 9.15236 9.7071 9.54289Z"
				:fill="color"
			/>
			<path
				d="M9.7071 9.54289C9.31658 9.93341 8.68341 9.93341 8.29289 9.54289C7.90236 9.15236 7.90236 8.5192 8.29289 8.12867L15.9497 0.471818C16.3403 0.0812935 16.9734 0.0812935 17.364 0.471818C17.7545 0.862342 17.7545 1.49551 17.364 1.88603L9.7071 9.54289Z"
				:fill="color"
			/>
		</g>
		<defs>
			<clipPath id="clip0_404_16">
				<rect width="18" height="10" fill="white" />
			</clipPath>
		</defs>
	</svg>

	<svg class="icon-vertical-arrow" v-if="isSizeMedium" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_404_14)">
			<path
				d="M7.7071 7.36396C7.31658 7.75448 6.68341 7.75448 6.29289 7.36396L0.636033 1.70711C0.245509 1.31658 0.245508 0.683417 0.636033 0.292893C1.02656 -0.0976311 1.65972 -0.0976311 2.05025 0.292893L7.7071 5.94975C8.09762 6.34027 8.09762 6.97344 7.7071 7.36396Z"
				fill="black"
			/>
			<path
				d="M7.7071 7.36396C7.31658 7.75448 6.68341 7.75448 6.29289 7.36396C5.90236 6.97344 5.90236 6.34027 6.29289 5.94975L11.9497 0.292893C12.3403 -0.0976309 12.9734 -0.0976311 13.364 0.292893C13.7545 0.683417 13.7545 1.31658 13.364 1.70711L7.7071 7.36396Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0_404_14">
				<rect width="14" height="8" fill="white" />
			</clipPath>
		</defs>
	</svg>

	<svg class="icon-vertical-arrow" v-if="isSizeSmall" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M6.70713 6.34188C6.31661 6.73241 5.68344 6.73241 5.29292 6.34188L1.05028 2.09924C0.659754 1.70872 0.659754 1.07555 1.05028 0.685029C1.4408 0.294505 2.07397 0.294505 2.46449 0.685029L6.70713 4.92767C7.09766 5.31819 7.09766 5.95136 6.70713 6.34188Z"
			:fill="color"
		/>
		<path
			d="M6.70713 6.34188C6.31661 6.73241 5.68344 6.73241 5.29292 6.34188C4.90239 5.95136 4.90239 5.31819 5.29292 4.92767L9.53556 0.685029C9.92608 0.294505 10.5592 0.294505 10.9498 0.685029C11.3403 1.07555 11.3403 1.70872 10.9498 2.09924L6.70713 6.34188Z"
			:fill="color"
		/>
	</svg>
</template>

<style scoped lang="scss"></style>
