<script lang="ts" setup>
	import type { SelectGroup, SelectOption } from "~/utils/types";

	const id = Math.random().toString(36);

	const shouldSlotShow = useShouldSlotShow();

	const props = withDefaults(
		defineProps<{
			value?: string;
			placeholder: string;
			required?: boolean;
			options?: SelectOption[];
			groups?: SelectGroup[];
			description?: string;
			disabled?: boolean;
		}>(),
		{
			value: "",
			required: true,
			disabled: false,
		}
	);

	const emit = defineEmits(["update:value"]);

	const value = computed({
		get() {
			return props.value;
		},
		set(value: string) {
			emit("update:value", value);
		},
	});

	if (!value.value && props.options?.length) value.value = props.options[0].value;

	const name = computed(() => {
		return props.placeholder.toLowerCase().replace(/ /g, "-");
	});
</script>

<template>
	<div class="input-select-field" :class="[{ disabled: disabled }]">
		<label :for="id">
			<span>{{ placeholder }}</span>
			<span v-if="required" class="star">&nbsp;*</span>
		</label>
		<span class="arrow"><icon-vertical-arrow size="medium" color="black" /></span>

		<select v-model="value" :name="name" :id="id" :placeholder="placeholder">
			<optgroup v-for="group in groups" :label="group.label" :key="group.label">
				<option v-for="option in group.options" :value="option.value" :key="option.value" :selected="option.value === value">
					{{ option.label }}
				</option>
			</optgroup>

			<option v-for="option in options" :value="option.value" :key="option.value" :selected="option.value === value">
				{{ option.label }}
			</option>
		</select>

		<p v-if="description?.length ? true : false" class="small slot description">
			{{ description }}
		</p>

		<div class="slot" v-if="shouldSlotShow">
			<slot></slot>
		</div>
	</div>
</template>

<style scoped lang="scss">
	.input-select-field {
		position: relative;
		display: flex;
		transition:
			all 0.1s ease-out,
			height 0s;
		width: 100%;
		@extend .gpu;
		pointer-events: all;
		flex-direction: column !important;
		text-align: left;
		font-size: 18px;

		.slot {
			margin-top: 6px;
			display: flex;
			width: 100%;
			flex-direction: column;
			@include SlideTop(0.2s, 2px);
		}

		.arrow {
			pointer-events: none;
			position: absolute;
			right: 14px;
			top: calc(56px / 2);
			transform: translateY(-50%);
			display: flex;
		}

		label {
			position: absolute;
			pointer-events: none;
			font-weight: normal;
			color: var(--grey);
			line-height: 34px;
			font-size: 10px;

			span.star {
				color: $red;
			}
		}

		select {
			@include AstonMartinSans(false);
			@include FontWeightRegular(false);
			width: 100%;
			background: var(--beige-light);
			border-radius: 8px;
			border: none;
			box-sizing: border-box;
			padding-top: 22px;
			line-height: 24px;
			padding-bottom: 10px;
			outline: none;
			color: var(--black);
		}

		label,
		select {
			padding-left: 14px;
			padding-right: 28px;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		&.disabled {
			pointer-events: none;
			select {
				background: none;
				color: var(--black);
				box-shadow: inset 0px 0px 0px 1px var(--beige-dark);
			}
		}
	}
</style>
